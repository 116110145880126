@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/lightmode';
@use 'sats-ui-lib/tokens/corner-radius';

.assistant {
  &__message-box {
    margin-top: spacing.$xl;
  }

  &__messages {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
    margin-top: spacing.$l;
    height: 400px;
    overflow: auto;
    border: 1px solid lightmode.$ui-border;
    border-radius: corner-radius.$s;
    background: lightmode.$background-secondary;
    padding: spacing.$m;
  }

  &__message-bot,
  &__message-human {
    border-radius: corner-radius.$s;
    width: 100%;
    display: flex;
    gap: spacing.$s;
  }

  &__message-icon {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    &--bot {
      color: lightmode.$on-primary;
      background: lightmode.$primary;
    }

    &--human {
      background: lightmode.$signal-success-background;
    }
  }

  &__message-text {
    padding-top: 8px;

    &--human {
      text-align: right;
      width: 100%;
    }
  }

  &__message-input-wrapper {
    display: flex;
    align-items: center;
    gap: spacing.$s;
    margin-top: spacing.$m;
    width: 100%;
  }

  &__message-input {
    width: 100%;
  }

  &__message-button {
    width: 100%;
    max-width: 150px;
  }

  &__feedback {
    margin-top: spacing.$l;
  }
}
